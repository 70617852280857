.page-footer {
  background-color: var(--font-color);
  color: var(--bg-color);
  border-radius: var(--border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  text-transform: uppercase;
  padding: calc(100vw / 32);
  margin-top: var(--margin);
  font-size: var(--font-size-large);
}

.footer-links {
  display: flex;
  margin-bottom: calc(100vw / 18);
}

.page-footer ul {
  width: 50%;
}

.page-footer li {
  letter-spacing: 0.05em;
  list-style: none;
}

.page-footer li a {
  line-height: 1;
}

/* .footer-copyright {
  position: relative;
  width: calc(100vw / 1.6);
  margin-bottom: calc(100vw / 64);
} */

/* .footer-copyright-symbol {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
} */

.page-footer .partner-link-with-logo {
  display: inline-flex;
  margin-right: var(--margin);
}

.page-footer .partner-link-with-logo img {
  width: auto;
  max-width: 100%;
  max-height: 75px;
  border-radius: 0;
}

@media screen and (min-width: 768px) {
  /* .footer-copyright {
    width: 50%;
  } */

  .page-footer li {
    letter-spacing: 0.03em;
  }
}

@media screen and (min-width: 1440px) {
  .footer-links {
    margin-bottom: 60px;
  }
}
