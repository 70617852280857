@import './overlay.css';

#consent-title {
  margin-bottom: calc(var(--margin) * 3);
}

.cookie-consent-overlay-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 9%;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--font-size);
}

.cookie-consent-overlay-inner .btn {
  max-width: 60%;
  background: var(--highlight-color);
  border-color: var(--bg-color);
  color: var(--bg-color);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.15;
  margin-left: 10%;
}

.cookie-consent-overlay-inner .btn {
  margin-top: var(--margin);
}

.cookie-consent-overlay-inner .btn-primary {
  background: var(--bg-color);
  border-color: var(--bg-color);
  color: var(--highlight-color);
}
