.overlay-backdrop {
  position: fixed;
  background: rgba(130, 139, 53, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--margin);
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.overlay-in {
  animation: fade-in 500ms ease;
  animation-fill-mode: forwards;
  will-change: opacity;
}

.overlay-backdrop:not(.overlay-in) {
  /* Hide it by default so people without JS can use the page */
  display: none;
}

.overlay {
  position: relative;
  height: 0;
  width: 100%;
  min-width: 300px;
  max-width: 660px;
  overflow: hidden;
  background: url('../../img/cookie-consent-blob.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: calc(285 / 300 * 100%);
  color: var(--bg-color);
  font-size: var(--font-size-large);
  text-align: center;
}

.overlay:focus {
  outline: none;
}

.overlay-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 9%;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#consent-title {
  margin-bottom: calc(var(--margin) * 3);
}

@media (min-width: 768px) {
  .overlay-backdrop {
    align-items: flex-start;
  }

  .overlay {
    width: 52%;
  }
}
