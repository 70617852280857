.page-header {
  position: relative;
  text-transform: uppercase;
  margin-bottom: 6%;
  line-height: 1;
  letter-spacing: 0.1em;
}

.page-header-homepage {
  line-height: 1;
}

.btn-hamburger-menu {
  margin-bottom: 2%;
  padding: calc(100vw * 0.00625);
}

.btn-hamburger-menu:focus {
  outline: none;
}

.hamburger-mobile {
  height: calc(var(--font-size-large) * var(--line-height));
  padding: 4px;
}

.hamburger-desktop {
  display: none;
}

.header-top {
  margin-bottom: 1%;
}

.header-banner {
  display: block;
}

.header-sub > :first-child {
  display: flex;
  margin-top: 4%;
  margin-bottom: 4%;
}

.header-year {
  width: 64%;
  margin-left: -7%;

  /* Workaround for Safari */
  height: 100%;
}

.header-event-data {
  display: flex;
}

.header-days {
  margin-left: 7%;
  margin-right: 15%;
}

.header-location {
  width: 53%;
}

.header-month {
  width: calc(1ch + 0.1em);
  word-break: break-all;
}

.header-days {
  width: calc(2ch + 0.1em);
}

.main-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1440px;
  margin: 0px auto;
  background: transparent;
  padding: var(--margin);
  z-index: 1000;
  transition: background-color 300ms ease-out;
}

.main-menu:focus {
  outline: none;
}

.main-menu-in {
  will-change: background-color;
  background: var(--bg-color);
}

.main-menu-links {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-menu-links > li {
  list-style: none;
  flex: 1;
  opacity: 0;
  transition: transform 400ms ease-out, opacity 200ms ease-out;
}

.main-menu-links > li:not(:nth-of-type(1)) {
  transform: scale(0.85);
}

.main-menu-in .main-menu-links > li {
  will-change: transform;
  transform: none;
  opacity: 1;
}

.main-menu-links > li:first-of-type {
  flex: initial;
}

.main-menu-links > li:first-of-type .btn {
  padding: calc(100vw * 0.00625);
}

.main-menu-links > li:not(:last-of-type) {
  margin-bottom: 1vh;
}

.main-menu-links > li > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  letter-spacing: 0.1em;
  font-size: 3.45vh;
}

.main-menu-links > .nav-language-switch {
  flex: initial;
  display: grid;
  column-gap: var(--margin);
  grid-template-columns: 1fr 1fr;
}

.main-menu-links > .nav-language-switch a {
  height: auto;
}

/* @media screen and (prefers-color-scheme: dark) {
  .page-header img {
    filter: invert(1);
  }
} */

.blinking-eyes {
  display: none;
  position: fixed;
  top: 10%;
  width: 100%;
  max-width: 1440px;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: opacity 400ms ease-in-out;
  z-index: 900;
}

@media screen and (min-width: 768px) {
  .page-header {
    display: grid;
    grid-template-columns: 57% auto;
    column-gap: 2.5%;
  }

  .page-header-homepage {
    display: block;
    font-size: calc(100vw / 21.5);
  }

  .header-top {
    position: relative;
    margin-bottom: 6px;
    grid-column: span 2;
    font-size: var(--font-size-large);
  }

  .page-header-homepage .header-top {
    font-size: inherit;
  }

  .btn-hamburger-menu {
    position: absolute;
    height: calc(100vw / 19.2);
    width: auto;
    padding: 0;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    background-color: transparent;
    top: 0;
    right: 0;
  }

  .page-header-homepage .btn-hamburger-menu {
    top: calc(-0.5 * 100vw / 30);
  }

  .hamburger-mobile {
    display: none;
  }

  .hamburger-desktop {
    display: block;
    height: 100%;
  }

  .hamburger-desktop-blob {
    fill: var(--font-color);
    stroke: var(--font-color);
    transition: fill 150ms;
    stroke-width: 1.5;
  }

  .hamburger-desktop-bars {
    fill: var(--bg-color);
    transition: fill 150ms;
  }

  .btn-hamburger-menu:hover .hamburger-desktop-blob,
  .btn-hamburger-menu:focus .hamburger-desktop-blob {
    fill: var(--bg-color);
  }

  .btn-hamburger-menu:hover .hamburger-desktop-bars,
  .btn-hamburger-menu:focus .hamburger-desktop-bars {
    fill: var(--font-color);
  }

  .header-sub {
    line-height: 93%;
    font-size: var(--font-size-small);
  }

  .page-header-homepage .header-sub {
    line-height: 1;
  }

  .header-sub > :first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .page-header-homepage .header-sub > :first-child {
    margin-top: 2%;
    margin-bottom: 4%;
  }

  .header-year {
    width: 60%;
    margin-left: -4%;
  }

  .header-event-data {
    align-items: flex-end;
  }

  .page-header-homepage .header-event-data {
    align-items: flex-start;
  }

  .main-menu-links > li {
    transition: transform 400ms ease-out, opacity 200ms ease-out;
  }

  .main-menu-links > li > .btn {
    letter-spacing: 0.03em;
    font-size: 4vh;
  }
}

@media screen and (min-width: 1440px) {
  .page-header-homepage {
    font-size: 67px;
  }

  .header-top {
    margin-bottom: 6px;
  }

  .page-header-homepage .header-top {
    margin-bottom: 12px;
  }

  .btn-hamburger-menu {
    height: 75px;
  }

  .main-menu {
    font-size: 48px;
  }
}